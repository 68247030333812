import request from "@/utils/request"

const orgApi = {
    SearchOrg: '/org/search',
    AddOrg: '/org/add',
    DeleteOrg: '/org/del',
    CheckOrgEnableDel: '/org/enable-del',
}

// 机构榜单分数
const orgScoreApi = {
    GetScore: '/org-score/list',
    AddScore: '/org-score/add',
    DeleteScore: '/org-score/del',
    EditScore: '/org-score/edit',
}

export function searchOrg(parameter) {
    return request({
        url: orgApi.SearchOrg,
        method: 'get',
        params: parameter
    })
}

export function addOrg(parameter) {
    return request({
        url: orgApi.AddOrg,
        method: 'post',
        data: parameter
    })
}

export function deleteOrg(parameter) {
    return request({
        url: orgApi.DeleteOrg + `/${parameter.id}`,
        method: 'post',
    })
}

export function checkOrgEnableDel(parameter) {
    return request({
        url: orgApi.CheckOrgEnableDel,
        method: 'get',
        params: parameter
    })
}

export function getOrganization(parameter) {
    return request({
        url: orgApi.AddScore,
        method: 'get',
        params: parameter.
        organizationId
    })
}

export function getScoreList(parameter) {
    return request({
        url: orgScoreApi.GetScore,
        method: 'get',
        params: parameter
    })
}

export function addScore(parameter) {
    return request({
        url: orgScoreApi.AddScore,
        method: 'post',
        data: parameter
    })
}

export function editScore(parameter) {
    return request({
        url: orgScoreApi.EditScore,
        method: 'post',
        data: parameter
    })
}

export function deleteScore(parameter) {
    return request({
        url: orgScoreApi.DeleteScore + `/${parameter.id}`,
        method: 'post',
    })
}