<template>
  <div>
    <div>
      <a-select
        show-search
        :value="value"
        :placeholder="type == 'org' ? '搜索机构' : '搜索公司'"
        style="width: 340px"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        :label-in-value="true"
        @search="handleSearch"
        @change="handleChange"
        @popupScroll="popupScroll"
      >
        <a-select-option v-for="d in data" :key="d.id">
          {{ d.name }}
        </a-select-option>
      </a-select>
      <a-button type="primary" style="margin-left: 5px;" @click="addRelate">
        <a-icon type="plus" />
      </a-button>
    </div>
    <div style="line-height: 30px">
      <a-tag
        v-for="item in relates"
        :key="item.id"
        closable
        @close="handleClose(item.id)"
      >
        {{ item.name }}
      </a-tag>
    </div>
  </div>
</template>

<script>
import { searchOrg } from "@/api/organization";
import { searchCompany } from "@/api/company";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
export default {
  name: "AddRelate",
  components: {},
  props: {
    type: {
      type: String,
      default: "org",
    },
    relateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
      value: undefined,
      keyword: undefined,
      relates: [],
      page: 0,
      allLoaded: false,
    };
  },
  created() {
    this.handleSearch = debounce(this.handleSearch, 800);
    this.popupScroll = throttle(this.popupScroll, 500);
    this.loadData();
  },
  methods: {
    loadData() {
      this.relates = this.relateList.map((item) => {
        return {
          id: item.companyId || item.organizationId,
          name: item.companyName || item.name,
        };
      });
    },
    handleSearch(value) {
      this.page = 0;
      this.allLoaded = false;
      this.keyword = value;
      if (this.type == "org") {
        searchOrg({ keyword: this.keyword, page: this.page }).then((res) => {
          this.data = res.data.content.map((i) => {
            return { id: i.id, name: i.name };
          });
          this.allLoaded = this.data.length < 10;
        });
      } else {
        searchCompany({
          keyword: this.keyword,
          page: this.page,
          pageSize: 10,
        }).then((res) => {
          this.data = res.data.content.map((i) => {
            return {
              id: i.companyId,
              name: i.companyName,
            };
          });
          this.allLoaded = this.data.length < 10;
        });
      }
    },
    handleChange(value) {
      this.value = value;
    },
    addRelate() {
      if (this.value.key && !this.relates.find((i) => i.id == this.value.key)) {
        this.relates.push({
          id: this.value.key,
          name: this.value.label,
        });
        this.$emit(
          "changeValue",
          this.relates.map((i) => i.id)
        );
      }
    },
    handleClose(id) {
      this.relates = this.relates.filter((i) => i.id != id);
      this.$emit(
        "changeValue",
        this.relates.map((i) => i.id)
      );
    },
    popupScroll(e) {
      if (this.allLoaded) {
        return;
      }
      const { target } = e;
      if (target.scrollTop + target.offsetHeight + 20 >= target.scrollHeight) {
        if (this.type == "org") {
          searchOrg({ keyword: this.keyword, page: ++this.page }).then(
            (res) => {
              let data = res.data.content.map((i) => {
                return { id: i.id, name: i.name };
              });
              this.allLoaded = data.length < 10;
              this.data = this.data.concat(data);
            }
          );
        } else {
          searchCompany({
            keyword: this.keyword,
            page: ++this.page,
            pageSize: 10,
          }).then((res) => {
            let data = res.data.content.map((i) => {
              return {
                id: i.companyId,
                name: i.companyName,
              };
            });
            this.allLoaded = data.length < 10;
            this.data = this.data.concat(data);
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
