<template>
  <div>
    <a-card :bordered="false">
      <a-button
        type="primary"
        icon="plus"
        @click="handleAdd"
        v-if="hasAction('add-user-org')"
        >新建</a-button
      >
      <a-table
        ref="table"
        size="default"
        :row-key="(record) => record.id"
        :columns="columns"
        :data-source="orgList"
        :pagination="{ pageSize: 10 }"
      >
        <div slot="action" slot-scope="record">
          <template>
            <a @click="handleEditInfo(record)" v-if="hasAction('edit-user-org')"
              >修改机构信息</a
            >
            <a-divider
              type="vertical"
              v-if="hasAction('edit-user-org') && hasAction('edit-user-org-group')"
            />
            <a @click="handleEditGroup(record)"
              > {{hasAction('edit-user-org-group') ? '修改机构用户组' : '查看机构用户组'}}</a
            >
            <a-divider
              type="vertical"
              v-if="hasAction('edit-user-org-group') && hasAction('delete-user-org')"
            />
            <a-popconfirm
              title="确认移除吗？"
              ok-text="是"
              cancel-text="否"
              @confirm="handleDelete(record)"
              v-if="hasAction('delete-user-org')"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </div>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { getUserOrgList, deleteUserOrg } from "@/api/auth";
import RuleEdit from "../components/RuleEdit";
import OrgInfo from './components/org-info.vue'

let columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "机构名称",
    dataIndex: "name",
  },
  {
    title: "机构备注",
    dataIndex: "notes",
  },
];

export default {
  data() {
    return {
      columns: columns,
      orgList: [],
      selectedRole: {},
    };
  },
  created() {
    if (
      (this.hasAction("edit-role") || this.hasAction("delete-role")) &&
      this.columns.length < 4
    ) {
      this.columns.push({
        title: "操作",
        scopedSlots: {
          customRender: "action",
        },
      });
    }
    this.loadData();
  },
  methods: {
    loadData() {
      getUserOrgList().then((res) => {
        if (res.code === 0) {
          this.orgList = res.data;
        }
      });
    },
    handleAdd() {
      this.$dialog(
        OrgInfo,
        {
          on:{
            ok:() => {this.loadData()}
          }
        },
        {
          title: "新增机构信息",
          width: 600,
          centered: true,
          maskClosable: false,
        }
      )
    },
    handleEdit(record) {
      this.selectedRole = record;
      this.showInfo = !this.showInfo;
    },
    handleDelete(record) {
      deleteUserOrg(record.id).then(() => {
        this.loadData();
      });
    },
    handleEditInfo(record) {
      this.$dialog(
        OrgInfo,
        {
          record,
          on:{
            ok:() => {this.loadData()}
          }
        },
        {
          title: "修改机构信息",
          width: 600,
          centered: true,
          maskClosable: false,
        }
      )
    },
    handleEditGroup(record) {
      this.$router.push({
        path:'/user-center/org-manage/list/options',
        query:{
          orgName:record.name,
          orgId:record.id
        }
      })
    },
    handle(record) {
      this.$dialog(
        RuleEdit,
        {
          record,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "权限管理",
          okText: "保存",
          width: 1000,
          centered: true,
          maskClosable: false,
        }
      );
    },
  },
};
</script>