<template>
  <a-tabs type="card" @change="callback">
    <a-tab-pane key="roleList" tab="机构列表"><org-list /></a-tab-pane>
  </a-tabs>
</template>

<script>
import OrgList from "./org-list";

export default {
  components: {
    OrgList,
  },
  methods: {
    callback() {},
  },
};
</script>