var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-form-item',{attrs:{"label":"机构名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                required: true,
                message: '请输入机构名称',
              } ],
            initialValue: _vm.record ? _vm.record.name : undefined,
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请输入机构名称',\n              },\n            ],\n            initialValue: record ? record.name : undefined,\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"机构备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'notes',
          { initialValue: _vm.record ? _vm.record.notes : undefined } ]),expression:"[\n          'notes',\n          { initialValue: record ? record.notes : undefined },\n        ]"}],attrs:{"rows":3}})],1),_c('a-form-item',{attrs:{"label":"企业关联机构实体"}},[_c('add-relate',{attrs:{"type":"org","relateList":_vm.record ? _vm.record.relateOrganization : []},on:{"changeValue":_vm.handleRelateOrgChange}})],1),_c('a-form-item',{attrs:{"label":"企业关联公司实体"}},[_c('add-relate',{attrs:{"type":"company","relateList":_vm.record ? _vm.record.relateCompany : []},on:{"changeValue":_vm.handleRelateCompanyChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }