<template>
  <div class="content">
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="机构名称">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [
                {
                  required: true,
                  message: '请输入机构名称',
                },
              ],
              initialValue: record ? record.name : undefined,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="机构备注">
        <a-textarea
          :rows="3"
          v-decorator="[
            'notes',
            { initialValue: record ? record.notes : undefined },
          ]"
        />
      </a-form-item>
      <a-form-item label="企业关联机构实体">
        <add-relate
          type="org"
          :relateList="record ? record.relateOrganization : []"
          @changeValue="handleRelateOrgChange"
        ></add-relate>
      </a-form-item>
      <a-form-item label="企业关联公司实体">
        <add-relate
          type="company"
          :relateList="record ? record.relateCompany : []"
          @changeValue="handleRelateCompanyChange"
        ></add-relate>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { saveUserOrg } from "@/api/auth";
import AddRelate from "./add-relate.vue";
export default {
  name: "org-info",
  components: {
    AddRelate,
  },
  props: {
    record: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      relateOrganization: [],
      relateCompany: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.relateOrganization = this.record
        ? this.record.relateOrganization.map((i) => i.organizationId)
        : [];
      this.relateCompany = this.record
        ? this.record.relateCompany.map((i) => i.companyId)
        : [];
    },
    handleRelateOrgChange(val) {
      this.relateOrganization = val;
    },
    handleRelateCompanyChange(val) {
      this.relateCompany = val;
    },
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let formData = {
              ...values,
              relateCompanyIds: this.relateCompany,
              relateOrganizationIds: this.relateOrganization,
            };
            if (this.record) {
              formData.orgId = this.record.id;
            }
            saveUserOrg(formData).then(() => {
              resolve(true);
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.content {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}
</style>
